//** React Imports
import axios from 'axios'
import Cookies from 'js-cookie'
import SecureLS from 'secure-ls'
import { UIPaths } from '../router/UIPaths'
import appConstants from './app.constants'
import appMessages from './app.messages'

const appUtils = () => {

  let service = {}
  

  /**
   * Set value in cookie
   * @param {*} key - the key using which cookie value will be set
   * @param {*} value 0 the value to be set
   */
  service.setCookie = (key, value) => {
    let newVal = value
    newVal = value.concat('=====').replaceAll('-', '=')
    newVal = btoa(newVal)
    Cookies.set(key, newVal, { expires: 1, sameSite: 'strict', secure: true });
  };

  /**
   * Get value from cookie. If not found, return default value
   * @param {string} key - the key using which cookie value will be retrieved
   * @param {optional string} defaultValue - default value to be returned, if not found in cookie
   * @returns {string} - Cookie value
   */
  service.getCookie = (key, defaultValue) => {
    defaultValue = defaultValue || ''
    let retVal = Cookies.get(key) || defaultValue;
    retVal = atob(retVal)
    retVal = retVal.replace('=====', '').replaceAll('=', '-')
    return retVal
  }

  /**
   * Delete cookie by string
   * @param {string} key 
   */
  service.deleteCookie = (key) => {
    Cookies.remove(key);
  }

  /**
   * Clear authentication related cookies
   */
  service.clearAuthData = () => {
    service.deleteLocalStorage(appConstants.localStorageKey.currentUser);
    service.deleteLocalStorage(appConstants.localStorageKey.customerID);
    service.deleteCookie(appConstants.cookieKey.authToken);
    service.deleteLocalStorage(appConstants.localStorageKey.activityList);
    
    service.deleteLocalStorage(appConstants.localStorageKey.listPageSearch);
  }

  /**
   * Get Logged in user's Auth token and customer ID
   * @returns Auth token
   */
  service.getLoggedInUserInfo = function() {
    var authToken = service.getCookie(appConstants.cookieKey.authToken, '')
    if (authToken === '') return {}

    var objCurrentUser = service.getLocalStorage(appConstants.localStorageKey.currentUser, {})
    return objCurrentUser;
  };

  service.getLoggedInUserCustomerID = function() {
    var customerID = service.getLocalStorage(appConstants.localStorageKey.customerID, 0);

    // if (customerID === 0) return;
    return customerID;
  };


  /**
   * Return logged in user's country
   */
   service.getLoggedInUserCountryID = () => {
    let loggedInUser = service.getLoggedInUserInfo()
    
    if (Object.keys(loggedInUser).length == 0)
      return 0
    return (loggedInUser.ctrID)
  }

  /**
   * Return logged in user's email
   */
   service.getLoggedInUserEmail = () => {
    let loggedInUser = service.getLoggedInUserInfo()
    
    if (Object.keys(loggedInUser).length == 0)
      return ''
    return (loggedInUser.em)
  }

  /**
   * Return logged in user's config keys
   */
   service.getLoggedInUserConfigValue = (configKeyCodeID) => {
    let loggedInUser = service.getLoggedInUserInfo()
    
    if (Object.keys(loggedInUser).length == 0)
      return ''
    const arr = loggedInUser.cfg ? Object.keys(loggedInUser.cfg) : [] 
    const found = arr.find((key) => (key == configKeyCodeID))
    if (found) {
      return loggedInUser.cfg[found]
    }
    return ''
  }

  service.getLoggedInUserAuthToken = () => {
    var authToken = service.getCookie(appConstants.cookieKey.authToken, '')
    return authToken
  }

  service.isUserLoggedIn = () => {
    var authToken = service.getCookie(appConstants.cookieKey.authToken, '')
    // console.log('auth token = ', authToken)
    if (authToken === '') return false

    // console.log('user logged in = true')
    return true
  }

  service.setLocalStorage = (key, value) => {
    var ls = new SecureLS({encodingType: 'aes'});
    ls.set(key, value)
    // localStorage.setItem(key, value)
  }

  service.getLocalStorage = (key, defaultValue) => {
    var ls = new SecureLS({encodingType: 'aes'});
    defaultValue = defaultValue || ''

    let sessionValue = ls.get(key) || defaultValue//localStorage.getItem(key) || defaultValue;
    if (typeof(defaultValue) === 'object') {
      try {
        sessionValue = JSON.parse(sessionValue)
      } catch {
        sessionValue = defaultValue
      }
    }
    return sessionValue
  }

  service.deleteLocalStorage = (key) => {
    // localStorage.removeItem(key)

    var ls = new SecureLS({encodingType: 'aes'});
    ls.remove(key)
  }



  /**
   * Make call to web service
   * @param {{url:'', method: '', data: ''}} options - Params required for making API call
   * @param {callback} cbSuccess - Success CB
   * @param {callback} cbError - Error CB
   * @param {callback} cbUnAuthAccess - Optional function to be called in case of UnAuth access
   * @returns - nothing
   */
  service.callWebService = (options, cbSuccess, cbError, cbUnAuthAccess) => {

    // Initialize URL. And if empty return.
    options.url = options.url || '';
    if (options.url === '') return;
  
    // By default set method to POST and data passed to the web-service as empty.
    options.method = options.method || 'POST';
    options.data = options.data || {};
    options.headers = {};
  
    
    if (options.url.startsWith(appConstants.spWSProtectedBaseUrl)) {

      if (options.url.startsWith(appConstants.spWSPublicBaseUrl + appConstants.spWSPaths.users.resetPassword)) {
        options.headers['x-reset-token'] = service.getCookie(appConstants.cookieKey.resetToken, '')
      }

      
      let loggedInUser = {}
      loggedInUser.authToken = service.getLoggedInUserAuthToken();
      loggedInUser.customerID = service.getLoggedInUserCustomerID();

      if ( loggedInUser.authToken != '') {
        //options.headers['Authorization'] = 'Bearer 497f03b3-de06-4f3a-82fb-8c1ce71d4830'//`Bearer ${loggedInUser.authToken}`
        options.headers['Authorization'] = `Bearer ${loggedInUser.authToken}`
      }

      if (loggedInUser.customerID != 0) {
        options.headers['customerID'] = loggedInUser.customerID
      }
    }
  
    // Axios call
    axios(options)
    .then(function successCallback(response) {
      // For login url, send the entire response, so as to retieve the auth token
      if ((options.url.startsWith(appConstants.spWSPublicBaseUrl + appConstants.spWSPaths.users.login)) ||
          (options.url.startsWith(appConstants.spWSPublicBaseUrl + appConstants.spWSPaths.registration.verifyToken)) ||
          (options.url.startsWith(appConstants.spWSPublicBaseUrl + appConstants.spWSPaths.registration.oauth))
         ) {
        cbSuccess(response);
      } else {
        // For non login request, send only the response data
        cbSuccess(response.data);
      }
    })
    .catch(function (error) {
      let defaultErrorResponse = {isSuccess: false, responseMessage: appConstants.messages.httpDefaultError}

      if (error.response) {
        const response = error.response;
        if (response.status === 500) {
          response.data = defaultErrorResponse
        }
  
        // server returns response with an error status.
        response.data = response.data || defaultErrorResponse;
  
        /* In case of session expiration, redirect to login screen */
        if (response.data.responseMessage === appConstants.messages.userAuthTokenFailure) {

          // delete auth info, before redirecting to login
          service.clearAuthData();

          // console.log('window loc = ', window.location.pathname, UIPaths.login )
          if (cbUnAuthAccess !== undefined) cbUnAuthAccess(response.data)

          // else do a explicit reload of current page - this will redirect to login via Router
          else window && window.location && 
              (window.location.pathname != UIPaths.login) && 
              (window.location.pathname.indexOf(UIPaths.register.step1) == -1) && 
              (window.location.pathname.indexOf(UIPaths.register.referralSignup.replace(':checkoutSessionID', '')) == -1) 
            // window.location.reload()
          
          // return <Redirect push to={UIPaths.login}/>
          // <Redirect to='/login'  />
        }
  
        if (cbError !== undefined) {
          if (options.url == appConstants.spWSPublicBaseUrl + appConstants.spWSPaths.users.login) {
            cbError(response)
          
          } else {
            cbError(response.data)
          }
        }
        else cbSuccess(response.data);
  
      } else if (error.request) {
        // console.log('in axios call error cb 1 - ', options.url)

        if (cbError !== undefined) cbError(defaultErrorResponse);
        else cbSuccess(response.data);

      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('in axios call error cb 2 - ', options.url, error)
        
        // if (cbError !== undefined) cbError(defaultErrorResponse);
        // else cbSuccess(response.data);
      }
      // console.log(error.config);
    });
  };


  service.isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  service.toInteger = (n) => {
    if (service.isNumber(n) === true)  
      return parseInt(n);
    return 0;
  }

  service.getDateDiff = (fromDate, toDate) => {
    const date1 = new Date(fromDate);
    const date2 = new Date(toDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    return diffDays;
  }

  service.getAppMessage = (key, arr=[]) => {
    // console.log('key = ', key)
    let message
    if ((key || '') === '') {
      return 'Error.'
    }
    try {
      message = appMessages[key] || 'Error...'
      for(var i = 0; i < arr.length; i++) {
        message = message.replaceAll('$' + i, arr[i])
      }
    } catch {
      message = 'Error!'
    }
    return message
  }

  service.getCDNImageUrl = (urlImageName) => {
    let baseUrl = process.env.REACT_APP_CDN_BASE_URL
    return (baseUrl + urlImageName);
  }

  service.getPageTitle = (pageTitle) => {
    return process.env.REACT_APP_BASENAME + ' | ' + pageTitle
  }

  service.isDevEnv = () => {
    return (!process.env.REACT_APP_IS_PROD)
  }
  service.getAvatarColor = (name) => {
    const darkBG = ['#626b85', '#353ae5', '#00808d', '#008d5d', '#48892a', '#c15700', '#dc0000', '#ef2fa7', '#7c33c4', 
                    '#838ca1', '#5988ff', '#28c9d3', '#00db99', '#76d64c', '#ffa42c', '#fd5e55', '#fc4bb6', '#ab73ef']
    var color = '#';

    try {
      const arrAlphabets = 'abcdefghijkl mnopqrstuvwxyz'.toUpperCase().split('');
      
      const sum = name.toUpperCase().split('').reduce((partialSum, a) => partialSum + arrAlphabets.indexOf(a) + 1, 0);
      const index = (sum%darkBG.length)
      
      return darkBG[index];
    } catch {
      return darkBG[1]
    }
  }

  service.getAvatarColor_orig = (name) => {
    var letters = '0123456789ABCDEF';
    var color = '#';

    try {
      const arrAlphabets = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
      
      const arrName = name.split(' ')
      const sum1 = arrName[0].toUpperCase().split('').reduce((partialSum, a) => partialSum + arrAlphabets.indexOf(a) + 1, 0);
      const sum2 = arrName[1].toUpperCase().split('').reduce((partialSum, a) => partialSum + arrAlphabets.indexOf(a) + 1, 0);

      // console.log('sum for = ', name, sum1, sum2)
      
      
      for (var i = 0; i < 6; i++) {
        if (i > 2 && i < 5) {
          color += letters[0]; // to generate dark colors
        } else {
          const multiplier = i+1
          const num = (sum1 * multiplier) + (sum2 * multiplier)
          // console.log('name = %s num = %d i = %d', name, num, i)
          color += letters[num%15];
        }
      }
      return color;
    } catch {
      return '#ff0000'
    }
  }
  

  service.convertToMarkdown = (rawContentState) => {
    let markdownText = '';

    rawContentState.blocks.forEach(block => {
      switch (block.type) {
        case 'header-one':
          markdownText += `# ${block.text}\n\n`;
          break;
        case 'header-two':
          markdownText += `## ${block.text}\n\n`;
          break;
        case 'header-three':
          markdownText += `### ${block.text}\n\n`;
          break;
        case 'header-four':
          markdownText += `#### ${block.text}\n\n`;
          break;
        case 'header-five':
          markdownText += `##### ${block.text}\n\n`;
          break;
        case 'header-six':
          markdownText += `###### ${block.text}\n\n`;
          break;
        case 'unordered-list-item':
          markdownText += `- ${block.text}\n`;
          break;
        case 'ordered-list-item':
          markdownText += `1. ${block.text}\n`;
          break;
        case 'blockquote':
          markdownText += `> ${block.text}\n\n`;
          break;
        case 'code-block':
          markdownText += `\`\`\`\n${block.text}\n\`\`\`\n\n`;
          break;
        default:
          markdownText += `${block.text}\n\n`;
          break;
      }

      if (block.inlineStyleRanges.length > 0) {
        let lastStyleEnd = 0;
        block.inlineStyleRanges.forEach(range => {
          markdownText += block.text.slice(lastStyleEnd, range.offset);
          switch (range.style) {
            case 'BOLD':
              markdownText += `**${block.text.slice(range.offset, range.offset + range.length)}**`;
              break;
            case 'ITALIC':
              markdownText += `*${block.text.slice(range.offset, range.offset + range.length)}*`;
              break;
            default:
              markdownText += block.text.slice(range.offset, range.offset + range.length);
              break;
          }
          lastStyleEnd = range.offset + range.length;
        });
        markdownText += block.text.slice(lastStyleEnd) + '\n\n';
      }
    });

    return markdownText.trim();
  };
  // service.getStoreDesignerUrl = () => {
  //   const dbAccessList = service.getLocalStorage(appConstants.localStorageKey.activityList, [])
  //   const found = dbAccessList.find((x) => (x.id == 22))
  //   return found.navLink
  // }

  return service
}
// const appUtils = tmp()
export default appUtils()